import { useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper/modules';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

const images = [
  {
    url: "image/saludable.jpg",
    caption: 'Slide 1'
  },
  {
    url: "image/immunocalspa.jpg",
    caption: 'Slide 2'
  },
  {
    url: "image/verano.jpg",
    caption: 'Slide 3'
  },
];

const Slider = () => {
  return (
    /*<div className="slide-container">
      <Slide>
       {images.map((slideImage, index)=> (
          <div className="each-slide" key={index}>
            <div style={{'backgroundImage': `url(${slideImage.url})`, height:'100%'}}>
              <span>{slideImage.caption}</span>
            </div>
          </div>
        ))} 
      </Slide>
    </div>*/

    <div className="slideshow-container">
      <Swiper
        modules={[Navigation, Autoplay]}
        spaceBetween={0}
        slidesPerView={1}
        loop={true}
        navigation={true}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper: any) => console.log(swiper)}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
      >

        {images.map((element: any, index: number) => {
          return (
            <SwiperSlide key={index}>
              <div className="preview mainimage">
                <img src={element.nombre} alt="" />
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>

    </div >

  )
}
export default Slider;