import './App.css';
import $ from 'jquery';
import { useEffect, useState } from 'react';
import Slider from './components/slider';

function App() {
  const [images, setImages] = useState<string[]>(["image/paqinic.jpg", "image/omegagen.png", "image/smile.jpg"]);
  const [imagesBg, setImagesBg] = useState<string[]>(["url(image/paqinic.jpg)", "url(image/omegagen.png)", "url(image/smile.jpg)"]);
  const [imageIndex, setImageIndex] = useState<number>(0);
  const [intervalId, setIntervalID] = useState<NodeJS.Timer>();
  const [sec, setSec] = useState<number>(0);
  const [scrollDir, setScrollDir] = useState("down");
  const [number430, setnumber430] = useState(0);
  const [number45, setnumber45] = useState(0);
  const [number78, setnumber78] = useState(0);
  const sleep = (ms: any) => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  useEffect(() => {
    window.addEventListener('DOMContentLoaded', setupLeft);
    function setupLeft() {
      const options = {
        rootMargin: '0px 0px -200px 0px'
      }
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            console.log("intersect")
            entry.target.classList.add('active');
            observer.unobserve(entry.target);
            console.log(entry.target.classList)
          } else {
            return;
          }
        })
      }, options);

      const paras = document.querySelectorAll('.goleft');
      console.log(paras)
      paras.forEach(p => observer.observe(p));
    }
    setupLeft();

  }, [])

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;
    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;
      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      if (!($('.uil-bars').hasClass('active'))) {
        $('.navbar').removeClass('active')
      }
      if (scrollY > 150) {
        //if (scrollY > lastScrollY) {
        //  if (!($('.uil-bars').hasClass('active'))) {
        //    $('.navbar2').removeClass('active')
        //    $('.hidden').removeClass('reveal')
        //  }
        //}
        //else {
          if (!($('.uil-bars').hasClass('active'))) {
            $('.navbar2').addClass('active')
            $('.hidden').addClass('reveal')
          }
        //}
      }
      else {
        if (!($('.uil-bars').hasClass('active'))) {
          $('.navbar2').removeClass('active')
          $('.hidden').removeClass('reveal')
        }
        if (($('.uil-bars').hasClass('active')) && !($('.navbar2').hasClass('active'))) {
          $('.navbar').addClass('active')
        }
      }

      setScrollDir(scrollY > lastScrollY ? "down" : "up");
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };
    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };
    window.addEventListener("scroll", onScroll);
    console.log(scrollDir);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollDir]);

  const slideimage = async (dir: string) => {
    setTimeout(() => {
      console.log("ok")
      if (dir == 'left') {
        let value = imageIndex - 1
        if (value < 0) {
          $('.slideimage').removeClass('active')
          setTimeout(() => {
            $('.slideimage').addClass('active')
            setImageIndex(2)
          }, 1000);
          $('.slider').css('background', `${imagesBg[2]} no-repeat`)
          $(".slider").css("background-size", "100% 100%");
        }
        else {
          $('.slideimage').removeClass('active')
          setTimeout(() => {
            $('.slideimage').addClass('active')
            setImageIndex(value)
          }, 1000);
          $('.slider').css('background', `${imagesBg[value]} no-repeat`)
          $(".slider").css("background-size", "100% 100%");
        }
      }
      if (dir == "right") {
        let value = imageIndex + 1
        if (value > 2) {
          $('.slideimage').removeClass('active')
          setTimeout(() => {
            $('.slideimage').addClass('active')
            setImageIndex(0)
          }, 1000);
          $('.slider').css('background', `${imagesBg[0]} no-repeat`)
          $(".slider").css("background-size", "100% 100%");
        }
        else {
          $('.slideimage').removeClass('active')
          setTimeout(() => {
            $('.slideimage').addClass('active')
            setImageIndex(value)
          }, 1000);
          $('.slider').css('background', `${imagesBg[value]} no-repeat`)
          $(".slider").css("background-size", "100% 100%");
        }
      }
    }, 1100);
  }
  const startTimer = () => {
    let intervalID = setInterval(() => {
      const now = new Date();
      const localTime = now.getTime();

      const seconds = Math.floor((localTime % (1000 * 60)) / 1000);
      setSec(seconds)

    }, 1000)
    setIntervalID(intervalID)
  }
  useEffect(() => {
    $('.slider').css('background', `${imagesBg[0]} no-repeat`)
    $(".slider").css("background-size", "100% 100%");
    $('.slideimage').addClass('active')
  }, [])
  useEffect(() => {
    startTimer();
    if (sec % 8 == 0 && sec != 0) {
      let value = imageIndex + 1
      if (value == 3) {
        $('.slideimage').removeClass('active')
        setTimeout(() => {
          setImageIndex(0)
          $('.slideimage').addClass('active')
        }, 1000);
        $('.slider').css('background', `${imagesBg[value]} no-repeat`)
        $(".slider").css("background-size", "100% 100%");
      }
      else {
        $('.slideimage').removeClass('active')
        setTimeout(() => {
          setImageIndex(value)
          $('.slideimage').addClass('active')
        }, 1000);
        $('.slider').css('background', `${imagesBg[value]} no-repeat`)
        $(".slider").css("background-size", "100% 100%");
      }
    }
    return () => {
      clearInterval(intervalId);
    }
  }, [sec])

  useEffect(() => {
    $('.whatsapp').on('click', function () {
      console.log("je")
      if ($('.whatsapp-active').hasClass('active')) {
        $('.whatsapp-active').removeClass('active')
      }
      else {
        $('.whatsapp-active').addClass('active')
      }
    })
    $('.immunotec').on('click', function () {
      if ($('.whatsapp-active').hasClass('active')) {
        $('.whatsapp-active').removeClass('active')
      }
    })
    $('.uil-bars').on('click', function () {
      if ($(this).hasClass('active')) {
        $(this).removeClass('active')
        $('.navbar-redirect').removeClass('active')
        $('.link-item').removeClass('active')
        $('main').removeClass('active')
        $('footer').removeClass('active')
        $('.slider').removeClass('active')
      }
      else {
        $(this).addClass('active')
        $('.navbar-redirect').addClass('active')
        $('.link-item').addClass('active')
        $('main').addClass('active')
        $('footer').addClass('active')
        $('.slider').addClass('active')
      }
    })
    $('.link-item').on('click', function () {
      if ($('.uil-bars').hasClass('active')) {
        $('.uil-bars').removeClass('active')
        $('.navbar-redirect').removeClass('active')
        $('.link-item').removeClass('active')
        $('main').removeClass('active')
        $('footer').removeClass('active')
        $('.slider').removeClass('active')
      }
    })
  }, [])

  return (
    <>
      <div className="whatsapp">
        <i className="uil uil-whatsapp"></i>
        <p>¡Contáctanos ahora!</p>
      </div>
      <div className="whatsapp-active">
        <div className="wsup">
          <h1>¡Hola!</h1>
          <p>Escríbenos y te responderemos lo más antes posible. Muchas gracias.</p>
        </div>
        <div onClick={() => { window.open('https://wa.me/51946157484?text=Hola me gustaría tener más información sobre el producto', '_blank') }} className="wsdown">
          <img src="image/iconf.png" alt="" />
          <div className="wstext">
            <p className='text-gray'>Únete a nuestra familia</p>
            <p>Ejecutivo Immunotec</p>
          </div>
        </div>
      </div>
      <div className="immunotec">
        <header>
          <div className="navbar">
            <img className='logo' src="image/nuevo-logo.png" alt="Immunoconsultor logo" />
            <div className="navbar-redirect">
              <ul className='redirect-links'>
                <li><a className='link-item' href="#quienessomos">quiénes somos</a></li>
                <li><a className='link-item' href="#immunocal">immunocal</a></li>
                <li><a className='link-item' href="#platinum">platinum</a></li>
                <li><a className='link-item' href="#ciencia">ciencia</a></li>
                <li><a className='link-item' href="#negocio">oportunidad de negocio</a></li>
                <li><a className='link-item' href="#contactanos">contáctanos</a></li>
              </ul>
            </div>
            <i className="uil uil-bars"></i>
          </div>
          <div className="navbar2">
            <img className='logo hidden' src="image/nuevo-logo.png" alt="Immunoconsultor logo" />
            <div className="navbar-redirect">
              <ul className='redirect-links'>
                <li><a className='hidden link-item' href="#quienessomos">quiénes somos</a></li>
                <li><a className='hidden link-item' href="#immunocal">immunocal</a></li>
                <li><a className='hidden link-item' href="#platinum">platinum</a></li>
                <li><a className='hidden link-item' href="#ciencia">ciencia</a></li>
                <li><a className='hidden link-item' href="#negocio">oportunidad de negocio</a></li>
                <li><a className='hidden link-item' href="#contactanos">contáctanos</a></li>
              </ul>
            </div>
            <i className="hidden uil uil-bars"></i>
          </div>
          <Slider></Slider>
            <div className="slider">
            <img className='slideimage prevslide' src={images[imageIndex]} alt="" />
            {/*<img className='slideimage posslide' src={imageIndex+1>2? images[0]:images[imageIndex+1]} alt="" />*/}
            <i onClick={() => slideimage('left')} className="uil uil-angle-left-b"></i>
            <i onClick={() => slideimage('right')} className="uil uil-angle-right-b"></i>
          </div>
        </header>
        <main>
          <section className='bordered-blue goleft' id='quienessomos'>
            <div className="container">
              <div className="up">
                <div className="left text-centered dml">
                  <h1 className='tb'>Sobre Immunotec</h1>
                  <p>Immunotec es una empresa Canadiense dedicada a la investigación del sistema inmunológico
                    por más de 45 años, con presencia en 14 países. Immunotec está aportando un mayor bienestar
                    y una mayor prosperidad en todo el mundo.</p>
                  <span className="button " onClick={() => { window.open('https://wa.me/51946157484?text=Hola me gustaría tener más información sobre el producto', '_blank') }}>quiero ser socio</span>
                </div>
                <div className="right dms">
                  <iframe width="100%" height="100%" src="https://www.youtube.com/embed/BKSVM2p5sRk?si=PNojNoBwu5KqBSar" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                </div>
              </div>
              <div className="down content-centered">
                <img className='flags' src="image/paises.png" alt="" />
              </div>
            </div>
          </section>
          <section className='bg-gray'>
            <div className="container goleft">
              <div className="up">
                <div className="left ds">
                  <div className="card-image flexbottom">
                    <img className='ceo' src="image/imagen.png" alt="" />
                    <div className="card-text">
                      <p className='text-bold'>Mauricio Domenzain</p>
                      <p>CEO Immunotec</p>
                    </div>
                  </div>
                </div>
                <div className="right dl">
                  <h1 className='tb'>Asóciate con Immunotec <br />
                    Pionero en Biotecnología</h1>
                  <p><span className='text-bold'>Todo empezó con la búsqueda del bienestar.</span> Hace más de cuarenta años, científicos de Montreal,
                    Canadá, descubrieron la función de un suero aislado de proteína especialmente preparado (ahora conocido como el famoso Immunocal).
                    Para mediados de los años 1990, estos científicos (que conocerás en la página 4) se asociaron con los empresarios veteranos
                    en marketing en red, Chuck Roberts, Dieter Beer y John Molson, miembro de la célebre familia canadiense Molson, para fundar
                    la empresa que ahora el mundo conoce como Immunotec.</p>
                  <p><span className='text-bold'>Desde el inicio, Immunotec era diferente.</span> Y lo que la hizo diferente fue que estos científicos de clase mundial, crearon el producto
                    insignia de la empresa, conocido como Immunocal, que ha logrado ser reconocido a nivel internacional. Es utilizado por atletas
                    profesionales de élite y aficionados.</p>
                  <span className="button text-centeredd" onClick={() => { window.open('https://wa.me/51946157484?text=Hola me gustaría tener más información sobre el producto', '_blank') }}>quiero ser parte de immunotec</span>
                </div>
              </div>
              <div className="down">
                <div className="image-container">
                  <div className="card-image card-padding">
                    <img src="image/houston.png" alt="" />
                    <span className='text-card text-bold'>Houston</span>
                  </div>
                  <div className="card-image card-padding">
                    <img src="image/montreal.png" alt="" />
                    <span className='text-card text-bold'>Montreal</span>
                  </div>
                  <div className="card-image card-padding">
                    <img src="image/mexico.png" alt="" />
                    <span className='text-card text-bold'>Ciudad de México</span>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className='bg-blue sectionumber'>
            <div className="container goleft">
              <div className="information-data">
                <div className="left dms flex-start modflex">
                  <div className='flex-centered'>
                    <span className='text-bold fs-big numbermod1'>+430</span>
                    <p>MILLONES DE PORCIONES VENDIDAS</p>
                  </div>
                  <div className='flex-centered'>
                    <span className='text-bold fs-big numbermod2'>45</span>
                    <p>AñOS DE INVESTIGACIÓN</p>
                  </div>
                  <div className='flex-centered'>
                    <span className='text-bold fs-big numbermod3'>78</span>
                    <p>PATENTES EMITIDAS</p>
                  </div>
                </div>
                <div className="right dml">
                  <iframe title="vimeo-player" src="https://player.vimeo.com/video/652517344?h=905259da54" width="100%" height="380px" frameBorder="0" allowFullScreen></iframe>
                </div>
              </div>
            </div>
          </section>
          <div className="container goleft">
            <div className="presentation-images">
              <img src="image/immunocal1.jpg" alt="" />
              <img src="image/immunocal2.jpg" alt="" />
            </div>
          </div>
          <section className='bg-blue' id='immunocal'>
            <div className="container bgap goleft">
              <div className="up">
                <div className="left diq row-padding">
                  <div className="flex-centered column">
                    <img src="image/paquete.png" alt="" />
                    <span className="button btn-white" onClick={() => { window.open('https://wa.me/51946157484?text=Hola me gustaría tener más información sobre el producto', '_blank') }}>quiero comprar el producto</span>
                  </div>
                </div>
                <div className="right diq nogap">
                  <h1 className='text-centered mb'>Immunocal fortalece el sistema inmunológico</h1>
                  <div className="underlined-text">
                    <span>I</span>
                    <p>Inmunológico</p>
                  </div>
                  <div className="underlined-text">
                    <span>D</span>
                    <p>Desintoxicador</p>
                  </div>
                  <div className="underlined-text">
                    <span>E</span>
                    <p>Energía Celular</p>
                  </div>
                  <div className="underlined-text">
                    <span>A</span>
                    <p>Antioxidante Maestro</p>
                  </div>
                </div>
              </div>
              <div className="down">
                <div className="left right-border dms">
                  <h1>¿Qué es Immunocal?</h1>
                  <p className='justify'>El Immunocal, originalmente llamado HMS-90, es un complemento alimenticio el cual suministra todos
                    los aminoácidos que necesita el cuerpo humano para poder llevar a cabo sus funciones biológicas.
                    Se comercializa en forma de polvo y se mezcla con agua, yogur o con zumo de frutas. Destaca por contener
                    grandes cantidades del aminoácido cisteína, el cual, mediante reacciones metabólicas, es transformado
                    en un potente antioxidante: el glutatión.</p>
                  <p className='justify'>
                    El glutatión es una sustancia cuya función principal es la de proteger las células frente a las sustancias
                    oxidantes. También interviene en procesos de detoxificación del organismo, combatiendo a patógenos tales
                    como virus, bacterias y otros agentes nocivos. También elimina radicales libres, los cuales afectan de
                    forma perjudicial a las células.
                  </p>
                </div>
                <div className="right dml sgap">
                  <h1 className='text-centeredd'>Beneficios de este Suplemento</h1>
                  <h2>Poder antioxidante y lucha contra los radicales libres</h2>
                  <p className='justify text-small'>Como hemos indicado, ayuda en la síntesis de sustancias antioxidantes como el glutatión, evitando así el envejecimiento
                    prematuro de las células. Cuando hay niveles satisfactorios de glutatión en el organismo se liberan vitaminas
                    como la A y la E, las cuales actúan como sustancias protectoras frente a agentes externos. Además, lucha contra
                    los radicales libres, los cuales están implicados en la muerte celular.</p>
                  <h2>Ayuda al sistema inmunológico</h2>
                  <p className='justify text-small'>El Immunocal contribuye en la producción de linfocitos, células fundamentales en el funcionamiento del sistema
                    inmunológico. También ayuda en el incremento de la actividad de otras células de gran importancia para el
                    sistema inmune, como son los linfocitos T, los neutrófilos y las células NK (natural killers), las cuales
                    están implicadas en los procesos infecciosos.</p>
                  <h2>Refuerza la actividad deportiva</h2>
                  <p className='justify text-small'>Este suplemento ontribuye en la recuperación muscular tras la realización de una actividad física de alto esfuerzo.
                    El desarrollo físico se da gracias a la acción de diferentes hormonas y a una correcta síntesis de proteínas. Para
                    poder formar masa muscular el organismo requiere de unos niveles satisfactorios de aminoácidos con los cuales se
                    harán proteínas que constituirán los músculos. Immunocal favorece en el desarrollo y mantenimiento de la masa muscular
                    gracias a que ofrece una gran variedad de aminoácidos.</p>
                </div>
              </div>
            </div>
          </section>
          <section className='bordered-blue' id='ciencia'>
            <div className="container goleft">
              <h1 className='text-centered tb'>La ciencia detrás del Immunocal</h1>
              <div className="flex-box supersmall">
                <div className="flex-item grid1 mgap">
                  <div className="flex-images">
                    <img src="image/cientifico-1.png" alt="" />
                    <img src="image/cientifica-2.png" alt="" />
                  </div>
                  <p className='text-centered'>A finales de los años 70 el trabajo de los Doctores Gustavo Bounous y Patricia Kongshavn
                    llevaron al descubrimiento de un aislado de suero lácteo.</p>
                  <p className='text-centered'>42 años de investigación y 401 artículos escritos por los creadores de Immunocal nos respaldan.
                    Su investigación condujo a grandes avances en los terrenos de la comprensión del sistema inmunológico.</p>
                </div>
                <div className="flex-item grid2 mgap">
                  <div className="flex-images">
                    <img src="image/cientifico-3.png" alt="" />
                  </div>
                  <p className='text-centered full-width'>Dr. Jimmy Gutman, Asesor Médico Principal de Immunotec, experto mundialmente conocido por el tema
                    del glutatión y autor de los libros sobre este tema.</p>
                </div>
                <div className="flex-item grid3 mgap">
                  <div className="flex-images">
                    <img className='books' src="image/books.png" alt="" />
                  </div>
                  <p className='text-bold text-centered'>19 años en el PDR y CPS: Libros de Referencia Médica y Farmacéutica en EEUU y Cánada.</p>
                  <p className='text-centered'>Dos de las fuentes de información médicas más respetadas en todo el mundo y en el rubro.</p>
                </div>
              </div>
            </div>
          </section>
          <section className='bg-gray bordered-blue'>
            <div className="container goleft">
              <div className="row">
                <div className="left dl">
                  <h1 className='tb'>Immunocal y glutatión</h1>
                  <p className='justify'>Algunos descubrimientos recientes han revelado información importante sobre el papel que juega el
                    glutatión en el mantenimiento de la salud. La mayoría de las personas no han escuchado hablar del glutatión.
                    Sin embargo, dado el ritmo acelerado de las investigaciones sobre este tema y el gran impacto que
                    tendrá en el futuro de los cuidados de la salud, el término glutatión pronto estará integrado en el
                    lenguaje cotidiano, de la misma manera que usamos los términos "antioxidante" o "colesterol".
                    <span className='text-bold'> Esta pequeña proteína, que produce el cuerpo de manera natural, mantiene funciones
                      esenciales de protección.</span></p>
                  <h1 className='h1-mod tb'>glutatión: El antioxidante maestro del cuerpo</h1>
                  <p className='justify'>Los avances en el estudio de los antioxidantes han crecido hasta el punto de  que se ha desarrollado
                    una nueva rama de la medicina llamada "Biología de Radicales Libres", que se centra en el equilibrio
                    oxidante/antioxidante. Estos científicos han desarrollado varios cientos de procesos biológicos
                    diferentes donde los antioxidantes desempeñan un papel vital. <span className='text-bold'> Lo extraordinario es que ninguno
                      de estos antioxidantes puede funcionar sin la presencia del glutatión que es responsable de recargar
                      y reciclar todos los más de 4,000 antioxidantes conocidos por la ciencia.</span></p>
                  <span className="button" onClick={() => { window.open('https://wa.me/51946157484?text=Hola me gustaría tener más información sobre el producto', '_blank') }}>quiero conocer más sobre glutation</span>
                </div>
                <div className="right ds content-centered">
                  <img className='bsbook' src="image/glutation.png" alt="" />
                </div>
              </div>
            </div>
          </section>
          <section className='bordered-blue' id='platinum'>
            <div className="container goleft">
              <div className="row sgap">
                <div className="left diq content-centered">
                  <img src="image/platinum.png" alt="" />
                </div>
                <div className="right diq">
                  <h1 className='text-centeredd'>Immunocal Platinum</h1>
                  <p className='text-bold'>Immunocal Platinum tiene todas las ventajas de Immunocal, además de los componentes CMP y RMF.</p>
                  <p className='justify'><span className='text-bold'>CMP (proteínas moduladoras de citoquina):</span> Las citoquinas son pequeñas proteínas
                    biológicamente activas que actúan como señales en el sistema inmunológico para regular la inflamación.</p>
                  <p className='justify'><span className="text-bold">RMF (Fórmula moduladora de redox):</span> La RMF incluye una mezcla exclusiva de citratos
                    minerales que actúan para reducir los niveles de acidez en el cuerpo y sirven para apoyar el balance natural del pH.
                    La inclusión de creatina en RMF, ayuda a mantener la función muscular adecuada.</p>
                  <span className="button text-centeredd" onClick={() => { window.open('https://wa.me/51946157484?text=Hola me gustaría tener más información sobre el producto', '_blank') }}>comprar immunocal platinum</span>
                </div>
              </div>
            </div>
          </section>
          <section className='bg-orange'>
            <div className="container goleft">
              <div className="row">
                <div className="left dms row-padding">
                  <img className='omegabs' src="image/omega.png" alt="" />
                </div>
                <div className="right content-centered dml">
                  <img src="image/omegav2.png" alt="" />
                  <h1>5 Ventajas del Omega Gen V</h1>
                  <div className="text-collection">
                    <p className="text-bold">1. Apoya la salud cardiovascular</p>
                    <p className="text-bold">2. Apoya la salud cognitiva</p>
                    <p className="text-bold">3. Apoya la salud ocular</p>
                    <p className="text-bold">4. Combatir la inflamación celular</p>
                    <p className="text-bold">5. Refuerzo al sistema inmunológico</p>
                  </div>
                  <div className="button" onClick={() => { window.open('https://wa.me/51946157484?text=Hola me gustaría tener más información sobre el producto', '_blank') }}>comprar omega gen v</div>
                </div>
              </div>
            </div>
          </section>
          <section className='bg-green'>
            <div className="container goleft">
              <div className="flex-box">
                <div className="flex-item diq content-centered">
                  <img src="image/optimizer.png" alt="" />
                </div>
                <div className="flex-item content-centered mgap">
                  <h1 className='text-centered'>Immunocal Optimizer</h1>
                  <p className='justify full-width'>El complemento perfecto para Immunocal® e Immunocal Platinum®. <span className="text-bold">¡La vida es increíble con Immunocal®! ¦</span> y
                    puede ser aún mejor con Optimizer® Rojos y Verdes. Impulsado por nuestro exclusivo Mediador del nrf2 con
                    una exclusiva mezcla de ROJOS & VERDES.</p>
                  <p className='justify full-width'>Mezcla en polvo a base de extracto de semilla de brócoli enriquecido con vitaminas y minerales sabor a frutos rojos,
                    para elaborar bebida. Immunocal Optimizer® está¡ basado en una investigación epigenética revolucionaria y preparado
                    con ingredientes respaldados científicamente.</p>
                </div>
                <div className="flex-item sgap">
                  <h1 className='mbs'>Beneficios</h1>
                  <p className='text-centered full-width'>Complemento perfecto
                    para Immunocal® e Immunocal Platinum®.</p>
                  <p className='text-centered full-width'>Apoya los antioxidantes incluyendo el glutatión.</p>
                  <p className='text-centered full-width'>más de 50 frutas y verduras orgánicas.</p>
                  <p className='text-centered full-width'>Ingredientes clínicamente estudiados.</p>
                  <p className='text-centered full-width'>Además de un delicioso
                    sabor cítrico de frutos rojos.</p>
                  <span className="button" onClick={() => { window.open('https://wa.me/51946157484?text=Hola me gustaría tener más información sobre el producto', '_blank') }}>comprar immunocal optimizer</span>
                </div>
              </div>
            </div>
          </section>
          <section className='bg-blue' id='negocio'>
            <div className="container goleft">
              <div className="row">
                <div className="left diq">
                  <h1 className='text-centered'>Tu oportunidad de generar ingresos</h1>
                  <p className='justify'>Las redes de mercado son un negocio recomendado por los mas exitosos empresarios y expertos economistas. Algunos beneficios:</p>
                  <div className="listed-text">
                    <ul>
                      <li>Productos patentados y validados científicamente</li>
                      <li>Respaldado por más de 40 años de investigación científica</li>
                      <li>Immunocal se vende desde hace más de 20 años</li>
                      <li>Conocimiento y expansión mundial</li>
                      <li>Oportunidad de ingresos empresariales</li>
                      <li>Alcanza tus metas financieras</li>
                    </ul>
                  </div>
                  <span className="button btn-white text-centeredd" onClick={() => { window.open('https://wa.me/51946157484?text=Hola me gustaría tener más información sobre el producto', '_blank') }}>quiero empezar a generar ingresos</span>
                </div>
                <div className="right diq">
                  <iframe title="vimeo-player" src="https://player.vimeo.com/video/653093765?h=f5c81c07d9" width="100%" height="100%" frameBorder="0" allowFullScreen></iframe>
                </div>
              </div>
            </div>
          </section>
          <section className='bg-gray bordered-blue'>
            <div className="container goleft">
              <div className="row">
                <div className="left dms">
                  <img src="image/fullness.png" alt="" />
                </div>
                <div className="right dml">
                  <h1 className='text-centered tb'>Un negocio basado en lo que quieren los consumidores</h1>
                  <p className="text-bold">La oportunidad de negocios de Immunotec puede ser tu trampolín al éxito.</p>
                  <p className='justify'><span className="text-bold">Bienestar y energía.</span> Estos son los temas centrales de la creciente industria de productos para el estilo
                    de vida. Immunotec combina las ventajas de una línea de productos para el bienestar de alta calidad,
                    con uno de los planes de compensación más generosos, para los Consultores Independientes en el mercado.</p>
                  <p className='justify'>Tu negocio basado en el hogar, se desarrollará mediante la venta de productos que la gente realmente necesita y
                    si eliges expandirte, puedes lograrlo a través del desarrollo de un equipo, conformado con otras personas que hacen
                    lo mismo. No desarrollarás tu negocio solo Como Consultor Independiente de Immunotec, cuentas con el apoyo de
                    Consultores Independientes experimentados que te guiarán y capacitarán.</p>
                  <span className="button text-centeredd" onClick={() => { window.open('https://wa.me/51946157484?text=Hola me gustaría tener más información sobre el producto', '_blank') }}>quiero empezar mi negocio</span>
                </div>
              </div>
            </div>
          </section>
          <div className="container goleft">
            <div className="row">
              <div className="left diq">
                <h1 className='text-centered'>Una increíble oportunidad de negocio para nuestro tiempo</h1>
                <p className='justify'>Bienvenido a una oportunidad de mercadeo en red que combina productos de primera calidad, capacitación y apoyo de clase mundial
                  y uno de los planes de compensación más generosos de la industria Bienestar, condición física, energía.</p>
                <p className='justify'>Estos son los temas centrales de la creciente industria de productos para el estilo de vida. Tu negocio, basado en el hogar,
                  se desarrollará mediante la venta de productos que la gente realmente necesita. Si eliges crecer, puedes lograrlo a través del
                  desarrollo de un equipo, conformado con otras personas que hacen lo mismo.</p>
                <span className="button text-centeredd" onClick={() => { window.open('https://wa.me/51946157484?text=Hola me gustaría tener más información sobre el producto', '_blank') }}>Únete a nuestra familia</span>
              </div>
              <div className="right diq content-centered">
                <img className='team-image' src="image/team.jpg" alt="" />
              </div>
            </div>
          </div>
          <section className='bg-blue' id='contactanos'>
            <div className="container goleft">
              <div className="row">
                <div className="left dms">
                  <img src="image/seo.png" alt="" />
                </div>
                <div className="right dml content-centered">
                  <h1>Empieza tu negocio hoy</h1>
                  <p className='justify'><span className='text-bold'>En immunotec tenemos 7 formas para generar ingresos.</span> Contáctanos y te asesoraremos de manera rápida y
                    confiable para que inicies en el negocio.</p>
                  <span className="button btn-white" onClick={() => { window.open('https://wa.me/51946157484?text=Hola me gustaría tener más información sobre el producto', '_blank') }}>Contáctanos ahora</span>
                </div>
              </div>
            </div>
          </section>
        </main>
        <footer>
          <p>Immunotec Online © Todos los derechos reservados.</p>
          <img className='logo' src="image/nuevo-logo.png" alt="" />
        </footer>
      </div>
    </>
  );
}

export default App;
